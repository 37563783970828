<template>
  <div>
    <KTCodePreview v-bind:title="'Cập nhật quyền'">
      <template v-slot:preview>
        <v-form
          ref="form"
          lazy-validation
        >
          <div class="col-md-12 mb-5">
            <div class="row">
              <div class="col-md-3">
                <b-row class="my-1">
                  <label class="label-input">Mã quyền:</label>
                  <span class="text-danger">*</span>
                </b-row>
                <b-row class="my-1">
                  <b-input
                    size="sm"
                    placeholder="Nhập mã quyền"
                    v-model="$v.form.code.$model"
                    :state="validateState('code')"
                    append-icon="search"
                    single-line
                    hide-details
                    :disabled="true"
                    class="col-md-12 form-control-sm"
                    aria-describedby="input-live-help input-live-feedback"
                  ></b-input>
                  <b-form-invalid-feedback id="input-live-feedback">Nhập mã với ít nhất 3 ký tự!</b-form-invalid-feedback>
                </b-row>
              </div>
              <div class="col-md-3">
                <b-row class="my-1">
                  <b-col>
                    <label class="label-input">Tên quyền:</label>
                    <span class="text-danger">*</span>
                  </b-col>
                </b-row>
                <b-row class="my-1">
                  <b-col>
                    <b-input
                      placeholder="Nhập tên quyền"
                      append-icon="search"
                      single-line
                      hide-details
                      class="col-md-12 form-control-sm"
                      v-model="$v.form.name.$model"
                      :state="validateState('name')"
                      aria-describedby="input-live-help input-name-feedback"
                    ></b-input>
                    <b-form-invalid-feedback id="input-name-feedback">
                      Nhập tên với ít nhất 3 ký tự!
                    </b-form-invalid-feedback>
                  </b-col>
                </b-row>
              </div>
            </div>
          </div>
          <div class="col-md-12">
            <p class="font-weight-bold">
              <strong style="font-size: 14px">1. Cài đặt chung</strong>
            </p>
            <div class="row">
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý người dùng</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionUserPermission"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý nhân sự</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listEmployeePermission"
                    :options="optionEmployeePermission"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý quyền</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listRolePermission"
                    :options="optionRolePermission"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
            </div>
            <p class="font-weight-bold">
              <strong style="font-size: 14px">2. Danh mục chung</strong>
            </p>
            <div class="row">
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý cửa hàng</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listStorePermission"
                    :options="optionStorePermission"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý nguồn đơn hàng</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listOrderSourcePermission"
                    :options="optionOrderSourcePermission"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý doanh nghiệp</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionCompanyPermision"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý khu vực</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionAreaPermision"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý vùng</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionRegionPermision"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý mã lỗi / mã sửa chữa</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionSymptomActionCodePermissions"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
            </div>
            <p class="font-weight-bold">
              <strong style="font-size: 14px">3. Khách hàng</strong>
            </p>
            <div class="row">
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý khách hàng</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listCustomerPermission"
                    :options="optionCustomerPermission"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý CSKH</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionCustomerCarePermision"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý điểm tích lũy</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionPointPermision"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
            </div>
            <p class="font-weight-bold">
              <strong style="font-size: 14px">4. Sản phẩm</strong>
            </p>
            <div class="row">
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý sản phẩm</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listProductPermission"
                    :options="optionProductPermission"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý danh mục sản phẩm</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listCatePermission"
                    :options="optionCatePermission"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý nhà cung cấp</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listProviderPermission"
                    :options="optionProviderPermission"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý thuộc tính sản phẩm</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listPropertyPermission"
                    :options="optionPropertyPermission"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý thương hiệu</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listBrandPermission"
                    :options="optionBrandPermission"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý IMEI</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionImeiPermission"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
            </div>
            <p class="font-weight-bold">
              <strong style="font-size: 14px">5. Kho</strong>
            </p>
            <div class="row">
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý kho hàng</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listStockPermission"
                    :options="optionStockPermission"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý phiếu yêu cầu nhập hàng</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionPOPermision"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý nhập kho</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionStockImport"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý xuất kho</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionStockExport"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
            </div>
            <p class="font-weight-bold">
              <strong style="font-size: 14px">6. Đơn hàng</strong>
            </p>
            <div class="row">
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý đơn hàng</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionOrderPermision"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
            </div>
            <p class="font-weight-bold">
              <strong style="font-size: 14px">7. Bán hàng</strong>
            </p>
            <div class="row">
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý bán hàng</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listBillPermission"
                    :options="optionBillPermission"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý trade-in</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionTradeInPermision"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý hóa đơn đỏ</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionVatInvoicePermission"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
            </div>
            <p class="font-weight-bold">
              <strong style="font-size: 14px">8. Kế toán</strong>
            </p>
            <div class="row">
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý tài khoản kế toán</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionAccountantPermision"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý công nợ</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionDebtPermision"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý bút toán</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionTransactionPermision"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý tài khoản trả góp</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionInstallmentPermision"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý danh mục thu chi</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionTransactionCatePermision"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
            </div>
            <p class="font-weight-bold">
              <strong style="font-size: 14px">9. Hoa hồng</strong>
            </p>
            <div class="row">
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý thưởng hoa hồng</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionRewardPermision"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Xem báo cáo thưởng hoa hồng</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionRewardReportPermision"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-md-4"></div>
            </div>
            <p class="font-weight-bold">
              <strong style="font-size: 14px">10. Báo cáo</strong>
            </p>
            <div class="row">
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Xem báo cáo</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionReportPermision"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Báo cáo POSM</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionPosmReportPermissions"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý Campaign POSM</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionCampaignPermissions"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý Form POSM</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionPosmFormPermissions"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Danh sách các Form POSM đã được điền</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionPosmSubmissionPermissions"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
            </div>
            <p class="font-weight-bold">
              <strong style="font-size: 14px">11. Cấu hình</strong>
            </p>
            <div class="row">
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Bộ phụ kiện tặng kèm</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionProductAttachPermision"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Bộ phụ kiện khuyến mãi</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionProductPromotionPermision"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Gói bảo hành</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionWarrantyPermision"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Chiết khấu</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionDiscountPermision"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Thu cũ- đổi mới</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionTradeInProgramPermision"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Coupon</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionCouponProgramPermision"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Tồn ảo sản phẩm</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionVirtualStockPermision"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>

              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Lý do bảo hành</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionWarrantyReasonPermision"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
            </div>
            <b-row>
              <b-col cols="4">
                <p class="font-weight-bold">
                  <strong>Cấu hình giá chi nhánh</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionProductPricePermision"
                  ></b-form-checkbox-group>
                </b-form-group>
              </b-col>
            </b-row>
            <div class="row">
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Phiếu chuyển của hàng</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionTransferStorePermission"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
            </div>
            <div class="row">
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý ca làm việc</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionShiftWorkPermission"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý cấu hình ca làm việc</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionShiftWorkConfigPermission"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
            </div>
            <p class="font-weight-bold">
              <strong style="font-size: 14px">12. Bảo hành theo IMEI</strong>
            </p>
            <div class="row">
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý thông tin IMEI</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionImeiManagePermissions"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
              <div class="col-md-4">
                <p class="font-weight-bold">
                  <strong>Quản lý thời hạn bảo hành theo IMEI</strong>
                </p>
                <b-form-group>
                  <b-form-checkbox-group
                    v-model="listUserPermission"
                    :options="optionImeiWarrantyPermissions"
                  ></b-form-checkbox-group>
                </b-form-group>
              </div>
            </div>
          </div>
        </v-form>
      </template>
      <template v-slot:foot>
        <b-button
          style="fontweight: 600; width: 70px"
          variant="primary"
          size="sm"
          type="submit"
          @click="updateRole"
        >Lưu</b-button>
        <router-link :to="'/permissions'">
          <b-button
            style="margin-left: 10px; font-weight: 600; width: 70px"
            variant="secondary"
            size="sm"
          >Hủy</b-button>
        </router-link>
      </template>
    </KTCodePreview>
  </div>
</template>
<style>
.center-label label {
  margin-bottom: 0px;
}

.label-input {
  font-weight: 500;
}
</style>
<script>
import KTCodePreview from '@/view/content/CodePreview.vue';
import { SET_BREADCRUMB } from '@/core/services/store/modules/breadcrumbs.module';
import { validationMixin } from 'vuelidate';
import { minLength, required } from 'vuelidate/lib/validators';
import ApiService from '@/core/services/api.service';
import localData from '../../../utils/saveDataToLocal';
import { v4 } from 'uuid';
import _ from 'lodash';

export default {
  mixins: [validationMixin],
  props: {
    id: {
      default: null,
      type: String,
    },
  },
  data() {
    return {
      valid: false,
      form: {
        code: '',
        name: '',
      },
      listUserPermission: [],
      optionUserPermission: [
        { text: 'Xem', value: 'userView' },
        { text: 'Thêm', value: 'userInsert' },
        { text: 'Cập nhật', value: 'userUpdate' },
        { text: 'Xóa', value: 'userDelete' },
      ],
      roleId: 0,
      listStorePermission: [],
      optionStorePermission: [
        { text: 'Xem', value: 'storeView' },
        { text: 'Thêm', value: 'storeInsert' },
        { text: 'Cập nhật', value: 'storeUpdate' },
        { text: 'Xóa', value: 'storeDelete' },
      ],
      listRolePermission: [],
      optionRolePermission: [
        { text: 'Xem', value: 'roleView' },
        { text: 'Thêm', value: 'roleInsert' },
        { text: 'Cập nhật', value: 'roleUpdate' },
        { text: 'Xóa', value: 'roleDelete' },
      ],
      listOrderSourcePermission: [],
      optionOrderSourcePermission: [
        { text: 'Xem', value: 'orderSourceView' },
        { text: 'Thêm', value: 'orderSourceInsert' },
        { text: 'Cập nhật', value: 'orderSourceUpdate' },
        { text: 'Xóa', value: 'orderSourceDelete' },
      ],
      listEmployeePermission: [],
      optionEmployeePermission: [
        { text: 'Xem', value: 'employeeView' },
        { text: 'Thêm', value: 'employeeInsert' },
        { text: 'Cập nhật', value: 'employeeUpdate' },
        { text: 'Xóa', value: 'employeeDelete' },
      ],
      listCustomerPermission: [],
      optionCustomerPermission: [
        { text: 'Xem', value: 'customerView' },
        { text: 'Thêm', value: 'customerInsert' },
        { text: 'Cập nhật', value: 'customerUpdate' },
        { text: 'Xóa', value: 'customerDelete' },
      ],
      listCatePermission: [],
      optionCatePermission: [
        { text: 'Xem', value: 'cateView' },
        { text: 'Thêm', value: 'cateInsert' },
        { text: 'Cập nhật', value: 'cateUpdate' },
        { text: 'Xóa', value: 'cateDelete' },
        { text: 'Nhập', value: 'cateMerge' },
        { text: 'Di chuyển', value: 'cateMove' },
      ],
      listBrandPermission: [],
      optionBrandPermission: [
        { text: 'Xem', value: 'brandView' },
        { text: 'Thêm', value: 'brandInsert' },
        { text: 'Cập nhật', value: 'brandUpdate' },
        { text: 'Xóa', value: 'brandDelete' },
      ],
      listProviderPermission: [],
      optionProviderPermission: [
        { text: 'Xem', value: 'providerView' },
        { text: 'Thêm', value: 'providerInsert' },
        { text: 'Cập nhật', value: 'providerUpdate' },
        { text: 'Xóa', value: 'providerDelete' },
      ],
      listPropertyPermission: [],
      optionPropertyPermission: [
        { text: 'Xem', value: 'propertyView' },
        { text: 'Thêm', value: 'propertyInsert' },
        { text: 'Cập nhật', value: 'propertyUpdate' },
        { text: 'Xóa', value: 'propertyDelete' },
      ],
      listProductPermission: [],
      optionProductPermission: [
        { text: 'Xem', value: 'productView' },
        { text: 'Thêm', value: 'productInsert' },
        { text: 'Cập nhật', value: 'productUpdate' },
        { text: 'Xóa', value: 'productDelete' },
        { text: 'Xem giá nhập', value: 'productOriginalPriceView' },
        { text: 'Thông tin VAT', value: 'productVatUpsert' },
      ],
      listStockPermission: [],
      optionStockPermission: [
        { text: 'Xem', value: 'stockView' },
        { text: 'Thêm', value: 'stockInsert' },
        { text: 'Cập nhật', value: 'stockUpdate' },
        { text: 'Xóa', value: 'stockDelete' },
        { text: 'Hủy phiếu', value: 'stockCancelTransfer' },
        { text: 'Xóa có lý do', value: 'stockDeleteTransfer' },
        { text: 'Lưu duyệt', value: 'stockApproveTransfer' },
      ],
      listBillPermission: [],
      optionBillPermission: [
        { text: 'Xem', value: 'billView' },
        { text: 'Thêm', value: 'billInsert' },
        { text: 'Cập nhật', value: 'billUpdate' },
        { text: 'Xóa', value: 'billDelete' },
        { text: 'Cập nhật vượt quá số ngày', value: 'billUpdateUnlimit' },
        { text: 'Trả hàng vượt quá số ngày', value: 'billInsertReturnUnlimit' },
        { text: 'Chỉnh sửa giá sản phẩm', value: 'billEditProductPrice' },
      ],
      listVatInvoicePermission: [],
      optionVatInvoicePermission: [
        { text: 'Xem', value: 'vatInvoiceView' },
        { text: 'Thêm', value: 'vatInvoiceInsert' },
        { text: 'Cập nhật', value: 'vatInvoiceUpdate' },
        { text: 'Đẩy qua mifi', value: 'vatInvoiceExportMifi' },
        { text: 'Xóa', value: 'vatInvoiceDelete' },
      ],
      optionTransactionPermision: [
        { text: 'Xem', value: 'transactionView' },
        { text: 'Thêm', value: 'transactionInsert' },
        { text: 'Cập nhật', value: 'transactionUpdate' },
        { text: 'Xóa', value: 'transactionDelete' },
        { text: 'Nhập excel ', value: 'transactionImportExcel' },
        { text: 'Thu chi lùi ngày', value: 'transactionPayPrevDay' },
      ],
      optionInstallmentPermision: [
        { text: 'Xem', value: 'installmentView' },
        { text: 'Thêm', value: 'installmentInsert' },
        { text: 'Cập nhật', value: 'installmentUpdate' },
        { text: 'Xóa', value: 'installmentDelete' },
      ],
      optionAccountantPermision: [
        { text: 'Xem', value: 'accountantView' },
        { text: 'Thêm', value: 'accountantInsert' },
        { text: 'Cập nhật', value: 'accountantUpdate' },
        { text: 'Xóa', value: 'accountantDelete' },
      ],
      optionDebtPermision: [
        { text: 'Xem công nợ nhà cung cấp', value: 'debtProviderView' },
        { text: 'Xem công nợ trả góp', value: 'debtInstallmentView' },
        { text: 'Xem công nợ quẹt thẻ', value: 'debtCreditView' },
      ],
      optionOrderPermision: [
        { text: 'Xem', value: 'orderView' },
        { text: 'Thêm', value: 'orderInsert' },
        { text: 'Cập nhật', value: 'orderUpdate' },
        { text: 'Xóa', value: 'orderDelete' },
        { text: 'Cập nhật cho kế toán', value: 'orderUpdateAccountant' },
      ],
      optionCustomerCarePermision: [
        { text: 'Xem', value: 'cusCareView' },
        { text: 'Thêm', value: 'cusCareInsert' },
        { text: 'Cập nhật', value: 'cusCareUpdate' },
        { text: 'Xóa', value: 'cusCareDelete' },
      ],
      optionPointPermision: [
        { text: 'Xem', value: 'pointView' },
        { text: 'Thêm', value: 'pointInsert' },
        { text: 'Cập nhật', value: 'pointUpdate' },
        { text: 'Xóa', value: 'pointDelete' },
      ],
      isNew: true,
      optionTradeInPermision: [
        { text: 'Xem', value: 'tradeInView' },
        { text: 'Thêm', value: 'tradeInInsert' },
        { text: 'Cập nhật', value: 'tradeInUpdate' },
        { text: 'Xóa', value: 'tradeInDelete' },
      ],
      optionRewardPermision: [
        { text: 'Xem', value: 'rewardView' },
        { text: 'Thêm', value: 'rewardInsert' },
        { text: 'Cập nhật', value: 'rewardUpdate' },
        { text: 'Xóa', value: 'rewardDelete' },
      ],
      optionRewardReportPermision: [
        { text: 'Tất cả', value: 'rrViewAll' },
        { text: 'Cá nhân', value: 'rrViewByEmp' },
        { text: 'Danh sách hóa đơn', value: 'rrViewBill' },
      ],
      optionProductAttachPermision: [
        { text: 'Xem', value: 'proAcctachView' },
        { text: 'Thêm', value: 'proAcctachInsert' },
        { text: 'Cập nhật', value: 'proAcctachUpdate' },
        { text: 'Xóa', value: 'proAcctachDelete' },
      ],
      optionProductPromotionPermision: [
        { text: 'Xem', value: 'proPromotionView' },
        { text: 'Thêm', value: 'proPromotionInsert' },
        { text: 'Cập nhật', value: 'proPromotionUpdate' },
        { text: 'Xóa', value: 'proPromotionDelete' },
      ],
      optionWarrantyPermision: [
        { text: 'Xem', value: 'warrantyView' },
        { text: 'Thêm', value: 'warrantyInsert' },
        { text: 'Cập nhật', value: 'warrantyUpdate' },
        { text: 'Xóa', value: 'warrantyDelete' },
      ],
      optionDiscountPermision: [
        { text: 'Xem', value: 'discountView' },
        { text: 'Thêm', value: 'discountInsert' },
        { text: 'Cập nhật', value: 'discountUpdate' },
        { text: 'Xóa', value: 'discountDelete' },
      ],
      optionTradeInProgramPermision: [
        { text: 'Xem', value: 'programTradeInView' },
        { text: 'Thêm', value: 'programTradeInInsert' },
        { text: 'Cập nhật', value: 'programTradeInUpdate' },
        { text: 'Xóa', value: 'programTradeInDelete' },
      ],
      optionCompanyPermision: [
        { text: 'Xem', value: 'companyView' },
        { text: 'Thêm', value: 'companyInsert' },
        { text: 'Cập nhật', value: 'companyUpdate' },
        { text: 'Xóa', value: 'companyDelete' },
      ],
      optionAreaPermision: [
        { text: 'Xem', value: 'areaView' },
        { text: 'Thêm', value: 'areaInsert' },
        { text: 'Cập nhật', value: 'areaUpdate' },
        { text: 'Xóa', value: 'areaDelete' },
      ],
      optionRegionPermision: [
        { text: 'Xem', value: 'regionView' },
        { text: 'Thêm', value: 'regionInsert' },
        { text: 'Cập nhật', value: 'regionUpdate' },
        { text: 'Xóa', value: 'regionDelete' },
      ],
      optionTransactionCatePermision: [
        { text: 'Xem', value: 'transCateView' },
        { text: 'Thêm', value: 'transCateInsert' },
        { text: 'Cập nhật', value: 'transCateUpdate' },
        { text: 'Xóa', value: 'transCateDelete' },
      ],
      optionReportPermision: [{ text: 'Có', value: 'reportView' }],
      optionCouponProgramPermision: [
        { text: 'Xem', value: 'couponView' },
        { text: 'Thêm', value: 'couponInsert' },
        { text: 'Cập nhật', value: 'couponUpdate' },
        { text: 'Xóa', value: 'couponDelete' },
      ],
      optionVirtualStockPermision: [
        { text: 'Xem', value: 'virtualStockView' },
        { text: 'Thêm', value: 'virtualStockInsert' },
        { text: 'Cập nhật', value: 'virtualStockUpdate' },
        { text: 'Xóa', value: 'virtualStockDelete' },
      ],
      optionWarrantyReasonPermision: [
        { text: 'Xem', value: 'warrantyReasonConfigView' },
        { text: 'Thêm', value: 'warrantyReasonConfigCreate' },
        { text: 'Cập nhật', value: 'warrantyReasonConfigUpdate' },
        { text: 'Xóa', value: 'warrantyReasonConfigDelete' },
      ],
      optionPOPermision: [
        { text: 'Xem', value: 'poView' },
        { text: 'Thêm', value: 'poInsert' },
        { text: 'Cập nhật', value: 'poUpdate' },
        { text: 'Xóa', value: 'poDelete' },
        { text: 'Duyệt', value: 'poPurchaseApprove' },
        { text: 'Xác nhận', value: 'poAccountanApprove' },
        { text: 'Hủy phiếu', value: 'poCancel' },
        { text: 'Nhập hàng', value: 'poImportStock' },
      ],
      optionProductPricePermision: [
        { text: 'Xem', value: 'productPriceView' },
        { text: 'Thêm', value: 'productPriceInsert' },
        { text: 'Xóa', value: 'productPriceDelete' },
        { text: 'Cập nhật', value: 'productPriceUpdate' },
      ],
      optionImeiPermission: [
        { text: 'Xem báo cáo kích hoạt', value: 'imeiActiveReportView' },
        { text: 'Kích hoạt', value: 'activeImei' },
        { text: 'Kích hoạt trên hóa đơn', value: 'activeImeiBill' },
      ],
      optionStockImport: [
        { text: 'Xem', value: 'stockImportView' },
        { text: 'Thêm', value: 'stockImportInsert' },
        { text: 'Cập nhật', value: 'stockImportUpdate' },
        { text: 'Xóa', value: 'stockImportDelete' },
      ],
      optionStockExport: [
        { text: 'Xem', value: 'stockExportView' },
        { text: 'Thêm', value: 'stockExportInsert' },
        { text: 'Cập nhật', value: 'stockExportUpdate' },
        { text: 'Xóa', value: 'stockExportDelete' },
      ],
      optionTransferStorePermission: [
        { text: 'Xem', value: 'transferStoreView' },
        { text: 'Thêm', value: 'transferStoreInsert' },
        { text: 'Cập nhật', value: 'transferStoreUpdate' },
        { text: 'Xóa', value: 'transferStoreDelete' },
      ],
      optionShiftWorkConfigPermission: [
        { text: 'Xem', value: 'shiftWorkConfigView' },
        { text: 'Thêm', value: 'shiftWorkConfigInsert' },
        { text: 'Cập nhật', value: 'shiftWorkConfigUpdate' },
        { text: 'Xóa', value: 'shiftWorkConfigDelete' },
      ],
      optionShiftWorkPermission: [
        { text: 'Xem', value: 'shiftWorkView' },
        { text: 'Thêm', value: 'shiftWorkInsert' },
        { text: 'Cập nhật', value: 'shiftWorkUpdate' },
        { text: 'Xóa', value: 'shiftWorkDelete' },
      ],
      optionCampaignPermissions: [
        { text: 'Xem', value: 'campaignView' },
        { text: 'Thêm', value: 'campaignInsert' },
        { text: 'Cập nhật', value: 'campaignUpdate' },
        { text: 'Xóa', value: 'campaignDelete' },
        { text: 'Xuất Excel', value: 'campaignExcelExport' },
      ],
      optionPosmFormPermissions: [
        { text: 'Xem', value: 'posmFormView' },
        { text: 'Thêm', value: 'posmFormInsert' },
        { text: 'Cập nhật', value: 'posmFormUpdate' },
        { text: 'Xóa', value: 'posmFormDelete' },
        { text: 'Nhập Excel', value: 'posmFormExcelImport' },
      ],
      optionPosmSubmissionPermissions: [
        { text: 'Xem', value: 'posmFormSubmissionView' },
      ],
      optionPosmReportPermissions: [{ text: 'Có', value: 'posmReportInsert' }],
      optionSymptomActionCodePermissions: [
        { text: 'Xem', value: 'symptomActionCodeView' },
        { text: 'Thêm', value: 'symptomActionCodeInsert' },
        { text: 'Cập nhật', value: 'symptomActionCodeUpdate' },
        { text: 'Xóa', value: 'symptomActionCodeDelete' },
      ],
      optionImeiManagePermissions: [
        { text: 'Xem', value: 'imeiManageView' },
        { text: 'Thêm', value: 'imeiManageInsert' },
        { text: 'Cập nhật', value: 'imeiManageUpdate' },
        { text: 'Xóa', value: 'imeiManageDelete' },
      ],
      optionImeiWarrantyPermissions: [
        { text: 'Xem', value: 'imeiWarrantyView' },
        { text: 'Thêm', value: 'imeiWarrantyInsert' },
        { text: 'Cập nhật', value: 'imeiWarrantyUpdate' },
        { text: 'Xóa', value: 'imeiWarrantyDelete' },
      ],
    };
  },
  validations: {
    form: {
      code: {
        required,
        minLength: minLength(3),
      },
      name: {
        required,
        minLength: minLength(3),
      },
    },
  },
  components: {
    KTCodePreview,
  },
  computed: {
    nameState() {
      return this.name.length > 2;
    },
  },
  mounted() {
    if (this.checkPermission('ROLE_UPDATE')) {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: 'Phân quyền', route: '/permissions' },
        { title: 'Danh sách quyền', route: '/permissions' },
        { title: 'Cập nhật quyền' },
      ]);
    } else {
      this.$store.dispatch(SET_BREADCRUMB, [
        { title: 'Phân quyền', route: '/permissions' },
        { title: 'Danh sách quyền', route: '/permissions' },
        { title: 'Thông tin quyền' },
      ]);
    }
    this.roleId = this.$route.query.id || this.$props.id;

    if (this.roleId) {
      this.getRoleById();
    }
  },

  methods: {
    newId() {
      return v4();
    },
    validateState(name) {
      if (!this.isNew) {
        const { $dirty, $error } = this.$v.form[name];
        return $dirty ? !$error : null;
      }
    },
    reset() {
      this.$refs.form.reset();
    },
    updateRole: async function () {
      this.isNew = false;
      this.$v.form.$touch();
      if (this.$v.form.$anyError) {
        return;
      }
      const code = this.$v.form.code.$model;
      const name = this.$v.form.name.$model;
      this.listStorePermission.forEach((element) => {
        this.listUserPermission.push(element);
      });
      this.listRolePermission.forEach((element) => {
        this.listUserPermission.push(element);
      });
      this.listOrderSourcePermission.forEach((element) => {
        this.listUserPermission.push(element);
      });
      this.listEmployeePermission.forEach((element) => {
        this.listUserPermission.push(element);
      });
      this.listCustomerPermission.forEach((element) => {
        this.listUserPermission.push(element);
      });
      this.listCatePermission.forEach((element) => {
        this.listUserPermission.push(element);
      });
      this.listBrandPermission.forEach((element) => {
        this.listUserPermission.push(element);
      });
      this.listProviderPermission.forEach((element) => {
        this.listUserPermission.push(element);
      });
      this.listPropertyPermission.forEach((element) => {
        this.listUserPermission.push(element);
      });
      this.listProductPermission.forEach((element) => {
        this.listUserPermission.push(element);
      });
      this.listStockPermission.forEach((element) => {
        this.listUserPermission.push(element);
      });
      this.listBillPermission.forEach((element) => {
        this.listUserPermission.push(element);
      });

      let data = {
        code: code,
        name: name,
        listFeature: this.listUserPermission,
      };
      ApiService.post('role/update', data)
        .then(({ data }) => {
          if (data.status === 1) {
            this.makeToastSuccess(data.message);
            setTimeout(() => {
              this.$router.push({
                path: '/permissions',
              });
            }, 1000);
          } else {
            this.makeToastFaile(data.message);
          }
        })
        .catch(({ response }) => {
          this.makeToastFaile(response.$error);
        });
    },
    getRoleById: function () {
      ApiService.setHeader();
      ApiService.get(`role/${this.roleId}`)
        .then(({ data }) => {
          if (data.status === 1) {
            this.form.code = data.data.id;
            this.form.name = data.data.name;
            data.data.Features.forEach((element) => {
              let feature = element.id;
              const isUserPremission =
                _.findIndex(
                  [
                    'installment',
                    'accountant',
                    'debt',
                    'transaction',
                    'order',
                    'point',
                    'cusCare',
                    'tradeIn',
                    'reward',
                    'rr',
                    'proAcctach',
                    'proPromotion',
                    'warranty',
                    'discount',
                    'programTradeIn',
                    'company',
                    'area',
                    'region',
                    'transCate',
                    'report',
                    'coupon',
                    'virtualStock',
                    'po',
                    'productPrice',
                    'activeImei',
                    'imeiActiveReportView',
                    'stockImport',
                    'stockExport',
                    'vatInvoice',
                    'transferStore',
                    'shiftWork',
                    'campaign',
                    'symptomActionCode',
                    'imeiManage',
                    'imeiWarranty',
                  ],
                  (f) => {
                    return _.startsWith(feature, f);
                  },
                ) > -1
                  ? true
                  : false;
              if (isUserPremission) {
                this.listUserPermission.push(feature);
              }
              if (feature.includes('user')) {
                this.listUserPermission.push(element.id);
              } else if (feature.includes('store')) {
                this.listStorePermission.push(element.id);
              } else if (feature.includes('role')) {
                this.listRolePermission.push(element.id);
              } else if (feature.includes('orderSource')) {
                this.listOrderSourcePermission.push(element.id);
              } else if (feature.includes('employee')) {
                this.listEmployeePermission.push(element.id);
              } else if (feature.includes('customer')) {
                this.listCustomerPermission.push(element.id);
              } else if (feature.includes('cate')) {
                this.listCatePermission.push(element.id);
              } else if (feature.includes('brand')) {
                this.listBrandPermission.push(element.id);
              } else if (feature.includes('provider')) {
                this.listProviderPermission.push(element.id);
              } else if (feature.includes('property')) {
                this.listPropertyPermission.push(element.id);
              } else if (feature.includes('product')) {
                this.listProductPermission.push(element.id);
              } else if (feature.includes('stock')) {
                this.listStockPermission.push(element.id);
              } else if (feature.includes('bill')) {
                this.listBillPermission.push(element.id);
              } else if (feature.includes('vatInvoice')) {
                this.listVatInvoicePermission.push(element.id);
              }
            });
          } else {
            this.makeToastFaile('Tải dữ liệu thất bại!');
          }
        })
        .catch(({ response }) => {
          this.makeToastFaile(response.$error);
        });
    },
    checkPermission: function (condition) {
      return localData.checkPermission(condition);
    },
    makeToastSuccess(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'success',
        solid: true,
      });
    },
    makeToastFaile(message) {
      this.$bvToast.toast(message, {
        title: `Thông báo`,
        variant: 'danger',
        solid: true,
      });
    },
  },
};
</script>
